import CheckIcon from '@mui/icons-material/Check';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowDownUp from '@mui/icons-material/KeyboardArrowUp';
import LinkIcon from '@mui/icons-material/Link';
import NotesIcon from '@mui/icons-material/Notes';
import RefreshIcon from '@mui/icons-material/Refresh';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  AlertColor,
  Badge,
  Box,
  Divider,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Menu,
  MenuItem,
  MenuList,
  Snackbar,
  Tooltip,
  Typography,
} from '@mui/material';
import { MouseEvent, useEffect, useRef, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { Link } from 'react-router-dom';
import { useGrekcy } from '../lib/GrekcyProvider';
import { ContextMenu, IContextMenu, PopupContextMenu } from '../lib/components/ContextMenu';
import { GetPocketIcon } from '../lib/components/Icons';
import { Article, ListArticleReq, Subscription, SubscriptionConfig, Unread } from '../lib/proto/grekcy_v1alpha2_pb';
import AddSubscrition from './AddSubscription';

export default function MyPage(): JSX.Element {
  const [loading, setLoading] = useState(false);
  const [subscriptions, setSubscriptions] = useState<Subscription.AsObject[]>([]);
  const [subscriptionConfigs, setSubscriptionConfig] = useState<SubscriptionConfig.AsObject[]>([]);
  const [unreads, setUnreads] = useState<Unread.AsObject[]>([]);
  const [feedId, setFeedId] = useState('grekcy:unread');
  const [activeFeedTitle, setActiveFeedTitle] = useState('');
  const [activeEntries, setActiveEntries] = useState<Article.AsObject[]>([]);
  const [entryId, setEntryId] = useState<string | undefined>(undefined);
  const [contentHeight, setContentHeight] = useState('auto');
  const [app, api] = useGrekcy();

  useEffect(() => {
    // FIXME
    function handleResize() {
      const e = window.document.getElementById('menu-bar');
      e && setContentHeight(`${window.innerHeight - e.offsetHeight}px`);
    }
    handleResize();

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const subscriptionsData = api.listSubscriptions();
        const configsData = api.listSubscriptionConfig();
        const unreadsData = api.listUnread('');

        const [subscriptions, configs, unreads] = await Promise.all([subscriptionsData, configsData, unreadsData]);
        setSubscriptions(subscriptions);
        setSubscriptionConfig(configs);
        setUnreads(unreads);
      } catch (e: any) {
        toast(e.message, 'error');
        setLoading(false);
      } finally {
        setLoading(false);
      }
    })();
  }, [api]);

  useEffect(() => {
    api
      .listArticle(feedId === 'grekcy:unread' ? '' : feedId, ListArticleReq.Mode.SNIPPT)
      .then((r) => {
        setActiveEntries(r);
        setEntryId('');
        setActiveFeedTitle(getFeedTitle(feedId));
      })
      .catch((e) => toast(e.message, 'error'));
  }, [api, feedId]);

  function getFeedTitle(feedId: string): string {
    if (feedId === 'grekcy:unread') return 'Unread';

    const s = subscriptions?.find((s) => s.id === feedId);
    return s ? s.title : 'untitled';
  }

  const allUnreadCount =
    unreads && unreads.length > 0 ? unreads.map((u: Unread.AsObject) => u.count).reduce((a, c) => a + c) : 0;

  const [feedContextMenu, setFeedContextMenu] = useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);
  const [contextFeedId, setContextFeedId] = useState<string | null>(null);

  function subscriptionsPanel(): JSX.Element {
    function handleFeedClick(id: string): void {
      setFeedId(id);
      entryListPanelRef.current && entryListPanelRef.current.scrollIntoView({ block: 'start' });
    }

    function handleContextMenu(e: MouseEvent, id: string) {
      e.preventDefault();

      setContextFeedId(id);
      setFeedContextMenu(feedContextMenu == null ? { mouseX: e.clientX + 2, mouseY: e.clientY - 6 } : null);
    }

    function handleContextMenuClose() {
      setFeedContextMenu(null);
    }

    function handleToggleReadabilityOpen() {
      handleContextMenuClose();

      if (!contextFeedId) return;

      let idx = subscriptionConfigs.findIndex((c) => c.feedId === contextFeedId);

      const updatedConfigs = subscriptionConfigs.slice();
      if (idx === -1) {
        const conf = new SubscriptionConfig();
        conf.setFeedId(contextFeedId);

        idx = updatedConfigs.push(conf.toObject());
      }

      let readability = updatedConfigs[idx].openReadability ? false : true;
      updatedConfigs[idx].openReadability = readability;

      api
        .updateSubscriptionConfig(contextFeedId, readability)
        .then((r) => setSubscriptionConfig(updatedConfigs))
        .catch((e) => toast(e.message, 'error'));
    }

    let openReadability = false;
    if (contextFeedId) {
      const configIdx = subscriptionConfigs.findIndex((c) => c.feedId === contextFeedId);
      openReadability = configIdx !== -1 && subscriptionConfigs[configIdx].openReadability;
    }

    return (
      <Box>
        <List dense>
          <ListItemButton
            key="grekcy:unread"
            autoFocus={feedId === 'grekcy:unread'}
            selected={feedId === 'grekcy:unread'}
            onClick={() => handleFeedClick('grekcy:unread')}
          >
            <ListItemText>
              <Typography noWrap>Unread</Typography>
            </ListItemText>

            <Badge badgeContent={allUnreadCount} color="primary" max={99999} />
          </ListItemButton>
          {/* TODO archive
          <ListItemButton selected={feedId === "grekcy:archive"}>
            <ListItemText
              primary="Archive"
              onClick={() => handleFeedClick("grekcy:archive")}
            />
          </ListItemButton> */}
          <ListSubheader>My Feeds</ListSubheader>
          <Divider />
          {!loading && (
            <>
              {subscriptions.map((s) => {
                const unread = unreads.find((u) => u.feedId === s.id);
                const selected = feedId === s.id;

                return (unread && unread.count > 0) || selected ? (
                  <>
                    <ListItemButton
                      onContextMenu={(e) => handleContextMenu(e, s.id)}
                      key={s.id}
                      autoFocus={selected}
                      selected={selected}
                      onClick={() => handleFeedClick(s.id)}
                    >
                      <ListItemText>
                        <Typography noWrap>{s.title}</Typography>
                      </ListItemText>
                      <Badge badgeContent={unread!.count} color="primary" max={999} />
                    </ListItemButton>
                  </>
                ) : (
                  <></>
                );
              })}
              <Menu
                open={feedContextMenu !== null}
                onClose={handleContextMenuClose}
                anchorReference="anchorPosition"
                anchorPosition={
                  feedContextMenu !== null
                    ? {
                        top: feedContextMenu.mouseY,
                        left: feedContextMenu.mouseX,
                      }
                    : undefined
                }
              >
                <MenuList>
                  <MenuItem onClick={handleToggleReadabilityOpen}>
                    <ListItemIcon>{openReadability && <CheckIcon />}</ListItemIcon>
                    <ListItemText>Open article as readability</ListItemText>
                  </MenuItem>
                  <Divider />
                  <MenuItem onClick={handleContextMenuClose}>
                    <ListItemIcon></ListItemIcon>
                    Unsubscribe....
                  </MenuItem>
                </MenuList>
              </Menu>
            </>
          )}
        </List>
        <Divider />
        <Box display={'flex'} justifyContent={'flex-end'} p={1}>
          <Tooltip title="refresh" placement="top">
            <RefreshIcon sx={{ cursor: 'pointer' }} />
          </Tooltip>
          <AddSubscrition />
        </Box>
      </Box>
    );
  }

  const [activeEntry, setActiveEntry] = useState<Article.AsObject | undefined>(undefined);
  const [activeContent, setActiveContent] = useState('');
  const [loadingReadability, setLoadingReadability] = useState(false);
  const [showingReadability, setShowingReadability] = useState(false);
  useEffect(() => {
    if (!entryId) return;

    api
      .getArticle(entryId!)
      .then((r) => {
        setActiveEntry(r);
        setTimeout(() => markAsRead(entryId), 500);
      })
      .catch((e) => toast(e.message, 'error'))
      .finally(() => setShowingReadability(false));
  }, [api, entryId]);

  useEffect(() => {
    if (entryPanelRef.current) {
      entryPanelRef.current.scrollIntoView();
      entryPanelRef.current.focus();
    }

    if (activeEntry) {
      setActiveContent(activeEntry.content.length > 0 ? activeEntry.content : activeEntry?.summary);

      const cfgIdx = subscriptionConfigs.findIndex((c) => c.feedId === activeEntry?.feedId);
      if (cfgIdx !== -1 && subscriptionConfigs[cfgIdx].openReadability) showReadability();
    } else {
      setActiveContent('');
    }
  }, [activeEntry]);

  const entryListPanelRef = useRef<HTMLElement>(null);
  const selectedItemRef = useRef<HTMLElement>(null);

  function EntryListPanel(): JSX.Element {
    let dateToDisplay: Date | undefined = undefined;

    function handleContextMenu(e: MouseEvent, articleId: string) {
      PopupContextMenu(e, contextMenuRef);
    }

    const contextMenuRef = useRef<IContextMenu>(null);

    return (
      <Box ref={entryListPanelRef}>
        <h3>{activeFeedTitle}</h3>
        <List dense>
          {activeEntries.map((e) => {
            let props: any = {};
            if (!e.readed) props.fontWeight = 'bold';

            var header: JSX.Element | undefined = undefined;
            if (!dateToDisplay || dateToDisplay.getDate() !== new Date(e.crawledAt!.seconds * 1000).getDate()) {
              dateToDisplay = new Date(e.crawledAt!.seconds * 1000);
              header = (
                <ListSubheader>
                  <Divider textAlign="left">{dateToDisplay?.toLocaleDateString()}</Divider>
                </ListSubheader>
              );
            }

            return (
              <>
                {header}
                <ListItemButton
                  key={e.id}
                  autoFocus={entryId === e.id}
                  selected={entryId === e.id}
                  onClick={() => handleArticleClick(e.id)}
                  onContextMenu={(evt) => handleContextMenu(evt, e.id)}
                >
                  <ListItemText ref={entryId === e.id ? selectedItemRef : null}>
                    <Typography noWrap>
                      <small>{e.crawledAt ? new Date(e.crawledAt?.seconds * 1000).toLocaleTimeString() : 'N/A'}</small>
                      <small> | {getFeedTitle(e.feedId)}</small>
                    </Typography>
                    <Typography sx={props}>{e.title}</Typography>
                  </ListItemText>
                </ListItemButton>
              </>
            );
          })}
        </List>

        <ContextMenu
          ref={contextMenuRef}
          items={[
            { label: 'Mark as unread' },
            { label: '-' },
            {
              label: 'Next article',
              icon: <KeyboardArrowDownIcon />,
              onClick: selectNextItem,
              hotkey: 'J',
            },
            {
              label: 'Previous article',
              icon: <KeyboardArrowDownUp />,
              onClick: selectPrevItem,
              hotkey: 'K',
            },
            { label: '-' },
            {
              label: 'Mark above as read..',
            },
            {
              label: 'Mark below as read..',
            },
            {
              label: 'Mark all as read..',
            },
          ]}
        />
      </Box>
    );

    function handleArticleClick(entryId: string): void {
      setEntryId(entryId);
    }
  }

  function markAsRead(id: string) {
    const currentIdx = activeEntries!.findIndex((e) => e.id === id);
    if (currentIdx === -1 || activeEntries![currentIdx].readed) return;

    api.markAsRead(id).then(() => {
      const updated = activeEntries!.slice();
      updated[currentIdx].readed = true;
      setActiveEntries(updated);

      api
        .listUnread(updated[currentIdx].feedId)
        .then((r) => {
          let updatedUnreads = unreads!.map((u: Unread.AsObject) => (u.feedId === r[0].feedId ? r[0] : u));
          setUnreads(updatedUnreads);
        })
        .catch((e) => toast(e.message, 'error'));
    });
  }

  function saveToPocket(id: string) {
    api
      .saveToPocket(id)
      .then(() => app.toast('saved to pocket', 'success'))
      .catch((e) => toast(e.message, 'error'));
  }

  const entryPanelRef = useRef<HTMLElement>(null);
  const entryLinkRef = useRef<HTMLAnchorElement>(null);
  const activeContentRef = useRef<HTMLElement>(null);

  useEffect(() => {
    if (activeContentRef.current) {
      const links = activeContentRef.current.getElementsByTagName('a');
      for (var i = 0, l = links.length; i < l; i++) {
        if (!links[i].href.startsWith('#')) links[i].target = '_blank';
      }
    }
  }, [activeContent]);

  function EntryPanel(): JSX.Element {
    return (
      <Box ref={entryPanelRef} tabIndex={0} sx={{ outline: '0px solid transparent' }}>
        {activeEntry && (
          <>
            <h1>{activeEntry.title}</h1>
            <Box>
              <Typography>
                <small>
                  {activeEntry.crawledAt ? new Date(activeEntry.crawledAt?.seconds * 1000).toLocaleString() : ''}
                </small>
                <small> | {getFeedTitle(activeEntry.feedId)}</small>
                {activeEntry.author && <small> | {activeEntry.author}</small>}
                <Tooltip title="Open link(O)" placement="top">
                  <Link ref={entryLinkRef} to={activeEntry.url} target="_blank" rel="noopener noreferrer">
                    <IconButton>
                      <LinkIcon fontSize="small" />
                    </IconButton>
                  </Link>
                </Tooltip>
                <IconButton onClick={() => saveToPocket(activeEntry.id)}>
                  <GetPocketIcon fontSize="small" />
                </IconButton>
                <Tooltip title="View as Readability(R)" placement="top">
                  <LoadingButton
                    loading={loadingReadability}
                    loadingPosition="start"
                    startIcon={<NotesIcon />}
                    variant={showingReadability ? 'contained' : 'text'}
                    onClick={() => getReadability(activeEntry.id)}
                  >
                    Readability
                  </LoadingButton>
                </Tooltip>
              </Typography>
            </Box>
            <Box
              ref={activeContentRef}
              dangerouslySetInnerHTML={{
                __html: activeContent,
              }}
            />
          </>
        )}
      </Box>
    );
  }

  // TODO loading 표시가 필요할 듯..
  // TODO readability view 인 것을 표시 필요함
  // TODO feed settings에 readability view로 여는 옵션 추가
  // TODO entry에 readability 결과 저장
  function getReadability(id: string): void {
    console.log(`getReadability: id=${id}, ${showingReadability}`);
    if (showingReadability) return;

    setLoadingReadability(true);
    api
      .getReadability(id)
      .then((r) => {
        setActiveContent(r);
        setShowingReadability(true);
      })
      .catch((e) => toast(e.message, 'error'))
      .finally(() => setLoadingReadability(false));
  }

  function toast(message: string, severity?: AlertColor): void {
    setToastMessage(message);
    setToastServerty(severity ? severity : 'success');
    setToastOpen(true);
  }

  const [toastOpen, setToastOpen] = useState(false);
  const [toastServerty, setToastServerty] = useState<AlertColor>('success');
  const [toastMessage, setToastMessage] = useState('');

  function Toast(): JSX.Element {
    return (
      <Snackbar
        open={toastOpen}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={6000}
        onClose={() => setToastOpen(false)}
      >
        <Alert onClose={() => setToastOpen(false)} severity={toastServerty} sx={{ width: '100%' }}>
          {toastMessage}
        </Alert>
      </Snackbar>
    );
  }

  function selectNextItem() {
    if (!entryId) {
      if (activeEntries.length > 0) {
        setEntryId(activeEntries[0].id);
        return;
      }
      return;
    }

    const currentIdx = activeEntries.findIndex((e) => e.id === entryId);
    if (currentIdx !== activeEntries.length - 1) {
      setEntryId(activeEntries[currentIdx + 1].id);
    } else selectNextFeed();
  }

  const selectPrevItem = () => {
    if (!entryId) {
      selectPrevFeed();
      return;
    }

    const currentIdx = activeEntries.findIndex((e) => e.id === entryId);
    if (currentIdx === -1) setEntryId(activeEntries[activeEntries.length - 1].id);
    else if (currentIdx !== 0) setEntryId(activeEntries[currentIdx - 1].id);
    else selectPrevFeed();
  };

  function selectNextFeed() {
    if (!feedId) {
      setFeedId('grekcy:unread');
      return;
    }

    const currentIndex = subscriptions.findIndex((s) => s.id === feedId);
    for (let i = currentIndex + 1; i < subscriptions.length; i++) {
      const id = subscriptions[i].id;
      const u = unreads.find((u) => u.feedId === id);
      if (u!.count > 0) {
        setFeedId(subscriptions[i].id);
        break;
      }
    }
  }

  function selectPrevFeed() {
    if (!feedId) {
      setFeedId('grekcy:unread');
      return;
    }

    const currentIndex = subscriptions.findIndex((s) => s.id === feedId);
    if (currentIndex === -1 || currentIndex === 0) {
      setFeedId('grekcy:unread');
      return;
    }

    for (let i = currentIndex - 1; i > -1; i--) {
      const id = subscriptions[i].id;
      const u = unreads.find((u) => u.feedId === id);
      if (u!.count > 0) {
        setFeedId(subscriptions[i].id);
        break;
      }
    }
  }

  function showReadability() {
    activeEntry && getReadability(activeEntry?.id);
  }

  function openEntryToNewTab() {
    activeEntry && entryLinkRef.current && entryLinkRef.current.click();
  }

  useHotkeys('j', selectNextItem);
  useHotkeys('k', selectPrevItem);
  useHotkeys('r', showReadability);
  useHotkeys('o', openEntryToNewTab);
  useHotkeys('p', () => activeEntry && saveToPocket(activeEntry?.id));

  return (
    <>
      <Box sx={{ display: 'flex', height: contentHeight }}>
        <Box
          sx={{
            width: 200,
            minWidth: 200,
            pr: 1,
            position: 'sticky',
            top: 0,
            overflowY: 'scroll',
            maxHeight: contentHeight,
          }}
        >
          {subscriptionsPanel()}
        </Box>
        <Box
          sx={{
            width: 250,
            minWidth: 250,
            position: 'sticky',
            top: 0,
            overflowY: 'scroll',
            maxHeight: contentHeight,
          }}
        >
          {EntryListPanel()}
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            pl: 2,
            pr: 2,
            position: 'sticky',
            top: 0,
            overflowY: 'scroll',
            maxHeight: contentHeight,
          }}
        >
          {EntryPanel()}
        </Box>
      </Box>
      <Toast />
    </>
  );
}
