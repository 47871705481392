import { AlertColor as muiAlertColor } from '@mui/material';
import { ReactNode, Ref, createContext, forwardRef, useContext, useImperativeHandle, useRef } from 'react';
import { GrekcyAPI } from '../grekcyAPI/Api';
import { AuthProvider, useAuth } from './AuthProvider';
import { IToast, Toast } from './components/toast';

export type AlertColor = muiAlertColor;

export interface IGrekcyApp {
  toast: (message: string, severity?: AlertColor) => void;
}

const GrekcyContext = createContext<IGrekcyApp | null>(null);

type GrekcyProviderProp = {
  app: IGrekcyApp;
  children: ReactNode;
}

export interface IGekcyProvider {
  toast: (message: string, severity?: AlertColor) => void;
}

export const GrekcyProvider = forwardRef(({ app, children }: GrekcyProviderProp, ref: Ref<IGekcyProvider>) => {
  useImperativeHandle(ref, () => ({
    toast(message: string, severity?: AlertColor) {
      toastRef.current && toastRef.current.toast(message, severity);
    },
  }));

  const toastRef = useRef<IToast>(null);

  return (
    <GrekcyContext.Provider value={app}>
      <AuthProvider>
        <GrekcyClientProvider>{children}</GrekcyClientProvider>
      </AuthProvider>
      <Toast ref={toastRef} />
    </GrekcyContext.Provider>
  );
});

export function useGrekcyApp() {
  const state = useContext(GrekcyContext);
  if (!state) {
    throw new Error('Can not find GrekcyProvider');
  }
  return state;
}

const GrekcyClientContext = createContext<GrekcyAPI | null>(null);

interface GrekcyClientProviderProps {
  children: ReactNode;
}

export function GrekcyClientProvider({ children }: GrekcyClientProviderProps) {
  const api = new GrekcyAPI(process.env.REACT_APP_API_ENDPOINT!, useAuth());

  return <GrekcyClientContext.Provider value={api}>{children}</GrekcyClientContext.Provider>;
}

export function useGrekcyClient() {
  const state = useContext(GrekcyClientContext);
  if (!state) {
    throw new Error('Cannot find GrekcyClientProvider');
  }
  return state;
}

export function useGrekcy(): [IGrekcyApp, GrekcyAPI] {
  return [useGrekcyApp(), useGrekcyClient()];
}
