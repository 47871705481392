import { Container } from '@mui/material';
import { useRef } from 'react';
import { Outlet, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import AboutPage from './AboutPage';
import MenuBar from './MenuBar';
import LoginPage from './auth/LoginPage';
import HomePage from './home/Homepage';
import { RequireAuth } from './lib/AuthProvider';
import { AlertColor, GrekcyProvider, IGekcyProvider, IGrekcyApp } from './lib/GrekcyProvider';
import MUIDemoPage from './muidemo';
import MyPage from './my/MyPage';

function App(): JSX.Element {
  const impl: IGrekcyApp = {
    toast(message: string, severity?: AlertColor) {
      grekcyRef.current && grekcyRef.current.toast(message, severity);
    },
  };

  const grekcyRef = useRef<IGekcyProvider>(null);

  return (
    <GrekcyProvider app={impl} ref={grekcyRef}>
      <Router>
        <MenuBar />
        <Container maxWidth={false} disableGutters>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route
              element={
                <RequireAuth>
                  <Outlet />
                </RequireAuth>
              }
            >
              <Route path="/my" element={<MyPage />} />
              <Route path="/mui" element={<MUIDemoPage />} />
            </Route>
          </Routes>
        </Container>
      </Router>
    </GrekcyProvider>
  );
}

export default App;
