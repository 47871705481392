import { Empty } from 'google-protobuf/google/protobuf/empty_pb';
import { StringValue } from 'google-protobuf/google/protobuf/wrappers_pb';
import { IAuthProvider } from '../lib/AuthProvider';
import { GrekcyClient } from '../lib/proto/Grekcy_v1alpha2ServiceClientPb';
import { GoogleLoginReq, ListArticleReq, UpdateSubscriptionConfigReq } from '../lib/proto/grekcy_v1alpha2_pb';

export class GrekcyAPI {
  s: GrekcyClient;

  constructor(endpoint: string, auth: IAuthProvider) {
    const authInterceptor = new AuthInterceptor(auth.getToken);
    const options = {
      unaryInterceptors: [authInterceptor],
      streamInterceptors: [authInterceptor],
    };
    this.s = new GrekcyClient(endpoint, null, options);
  }

  version() {
    return this.s.version(new Empty(), null).then((r) => r.toObject().value);
  }

  loginWithGoogle(credential: string, clientId?: string, extra?: string) {
    const req = new GoogleLoginReq();
    req.setCredential(credential);
    clientId && req.setClientId(clientId);
    extra && req.setExtra(extra);

    return this.s.loginWithGoogle(req, null).then((r) => r.toObject().value);
  }

  listSubscriptions() {
    return this.s.listSubscriptions(new Empty(), null).then((r) => r.toObject().itemsList);
  }

  getSubscription() {}

  createSubscription(url: string) {
    const req = new StringValue();
    req.setValue(url);

    return this.s.createSubscription(req, null).then((r) => r.toObject());
  }

  listSubscriptionConfig() {
    return this.s.listSubscriptionConfig(new Empty(), null).then((r) => r.toObject().itemsList);
  }

  updateSubscriptionConfig(id: string, openReadability: boolean) {
    const req = new UpdateSubscriptionConfigReq();
    req.setFeedId(id);
    req.setOpenReadability(openReadability);

    return this.s.updateSubscriptionConfig(req, null).then((r) => r.toObject());
  }

  parseUrlForSubscription(url: string) {
    const req = new StringValue();
    req.setValue(url);
    return this.s.parseUrlForSubscription(req, null).then((r) => r.toObject());
  }

  listUnread(feedId?: string) {
    const req = new StringValue();
    if (feedId) req.setValue(feedId);
    return this.s.listUnread(req, null).then((r) => r.toObject().itemsList);
  }

  listArticle(feedId: string, mode: ListArticleReq.Mode) {
    const req = new ListArticleReq();
    req.setFeedId(feedId);
    req.setMode(mode);
    return this.s.listArticle(req, null).then((r) => r.toObject().itemsList);
  }

  getArticle(id: string) {
    const req = new StringValue();
    req.setValue(id);

    return this.s.getArticle(req, null).then((r) => r.toObject());
  }

  getReadability(id: string) {
    const req = new StringValue();
    req.setValue(id);
    return this.s.getReadability(req, null).then((r) => r.toObject().value);
  }

  markAsRead(id: string) {
    const req = new StringValue();
    req.setValue(id);

    return this.s.markAsRead(req, null).then((r) => r.toObject());
  }

  saveToPocket(id: string) {
    const req = new StringValue();
    req.setValue(id);

    return this.s.saveToPocket(req, null).then((r) => r.toObject());
  }
}

class AuthInterceptor {
  getToken: () => string | null;

  constructor(tokenGetter: () => string | null) {
    this.getToken = tokenGetter;
  }

  intercept(request: any, invoker: any) {
    const metadata = request.getMetadata();
    const token = this.getToken();

    if (token) {
      metadata['Access-Control-Allow-Origin'] = '*';
      metadata.Authorization = 'Bearer ' + token;
    }
    return invoker(request);
  }
}
