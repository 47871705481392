import { SvgIcon, SvgIconProps } from '@mui/material';

export function EmptyIcon(props: SvgIconProps) {
  return <SvgIcon {...props}></SvgIcon>;
}

// Adopted from https://iconscout.com/icons/getpocket
export function GetPocketIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M12.01,22.5C5.388,22.5,0,17.135,0,10.541V3.78C0,2.273,1.277,1,2.789,1h18.432C22.753,1,24,2.247,24,3.78v6.761C24,17.135,18.621,22.5,12.01,22.5z M2.789,2.5C2.09,2.5,1.5,3.086,1.5,3.78v6.761C1.5,16.308,6.215,21,12.01,21c5.784,0,10.49-4.692,10.49-10.459V3.78c0-0.718-0.562-1.28-1.279-1.28H2.789z"></path>
      <path d="M12.006,16.352c-0.579,0-1.153-0.216-1.583-0.647c-1.208-1.152-2.171-2.059-2.93-2.773c-2.945-2.773-3.06-2.881-3.06-3.916c0-1.26,1.028-2.285,2.292-2.285c0.97,0,1.205,0.228,3.451,2.407c0.493,0.479,1.093,1.061,1.832,1.77c0.714-0.684,1.298-1.25,1.783-1.718c2.35-2.273,2.542-2.459,3.515-2.459c1.264,0,2.292,1.025,2.292,2.285c0,1.022-0.175,1.188-3.091,3.942c-0.753,0.711-1.704,1.61-2.906,2.759C13.155,16.141,12.579,16.352,12.006,16.352z M6.726,8.23c-0.437,0-0.792,0.352-0.792,0.785c0,0.365,0,0.387,2.588,2.824c0.761,0.716,1.726,1.625,2.95,2.792c0.263,0.264,0.758,0.318,1.097-0.001c1.202-1.15,2.155-2.051,2.909-2.763c2.621-2.476,2.621-2.512,2.621-2.852c0-0.433-0.355-0.785-0.792-0.785c-0.346,0-0.367,0-2.472,2.037c-0.599,0.579-1.35,1.307-2.308,2.221c-0.29,0.276-0.746,0.276-1.036,0c-0.986-0.942-1.753-1.687-2.359-2.274C7.087,8.23,7.06,8.23,6.726,8.23z"></path>
    </SvgIcon>
  );
}

export function GetPocketSavedIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        fill="#E6364B"
        d="M0.78,10.784c0,6.266,4.993,11.267,11.229,11.267c6.211,0,11.21-5,11.21-11.267V3.989c0-1.126-0.887-2.04-2.024-2.04H2.814c-1.107,0-2.034,0.929-2.034,2.04V10.784z"
      ></path>
      <path
        fill="#FFF"
        d="M6.374,7.41c0.911,0,0.863,0.204,5.635,4.8c4.854-4.67,4.746-4.8,5.651-4.8c0.905,0,1.645,0.742,1.645,1.65c0,0.956-0.156,0.844-6.15,6.622h0.001c-0.664,0.634-1.682,0.596-2.272,0c-6.091-5.854-6.155-5.65-6.155-6.622C4.729,8.152,5.468,7.41,6.374,7.41z"
      ></path>
      <path d="M12.01,22.5C5.388,22.5,0,17.135,0,10.541V3.78C0,2.273,1.277,1,2.789,1h18.432C22.753,1,24,2.247,24,3.78v6.761C24,17.135,18.621,22.5,12.01,22.5z M2.789,2.5C2.09,2.5,1.5,3.086,1.5,3.78v6.761C1.5,16.308,6.215,21,12.01,21c5.784,0,10.49-4.692,10.49-10.459V3.78c0-0.718-0.562-1.28-1.279-1.28H2.789z"></path>
      <path d="M12.006,16.352c-0.579,0-1.153-0.216-1.583-0.647c-1.208-1.152-2.171-2.059-2.93-2.773c-2.945-2.773-3.06-2.881-3.06-3.916c0-1.26,1.028-2.285,2.292-2.285c0.97,0,1.205,0.228,3.451,2.407c0.493,0.479,1.093,1.061,1.832,1.77c0.714-0.684,1.298-1.25,1.783-1.718c2.35-2.273,2.542-2.459,3.515-2.459c1.264,0,2.292,1.025,2.292,2.285c0,1.022-0.175,1.188-3.091,3.942c-0.753,0.711-1.704,1.61-2.906,2.759C13.155,16.141,12.579,16.352,12.006,16.352z M6.726,8.23c-0.437,0-0.792,0.352-0.792,0.785c0,0.365,0,0.387,2.588,2.824c0.761,0.716,1.726,1.625,2.95,2.792c0.263,0.264,0.758,0.318,1.097-0.001c1.202-1.15,2.155-2.051,2.909-2.763c2.621-2.476,2.621-2.512,2.621-2.852c0-0.433-0.355-0.785-0.792-0.785c-0.346,0-0.367,0-2.472,2.037c-0.599,0.579-1.35,1.307-2.308,2.221c-0.29,0.276-0.746,0.276-1.036,0c-0.986-0.942-1.753-1.687-2.359-2.274C7.087,8.23,7.06,8.23,6.726,8.23z"></path>
    </SvgIcon>
  );
}
