// source: grekcy_v1alpha2.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.api.grekcy.v1alpha2.Article', null, global);
goog.exportSymbol('proto.api.grekcy.v1alpha2.Feed', null, global);
goog.exportSymbol('proto.api.grekcy.v1alpha2.GoogleLoginReq', null, global);
goog.exportSymbol('proto.api.grekcy.v1alpha2.ListArticleReq', null, global);
goog.exportSymbol('proto.api.grekcy.v1alpha2.ListArticleReq.Mode', null, global);
goog.exportSymbol('proto.api.grekcy.v1alpha2.ListArticleResp', null, global);
goog.exportSymbol('proto.api.grekcy.v1alpha2.ListOrder', null, global);
goog.exportSymbol('proto.api.grekcy.v1alpha2.ListSubscriptionConfigResp', null, global);
goog.exportSymbol('proto.api.grekcy.v1alpha2.ListSubscriptionResp', null, global);
goog.exportSymbol('proto.api.grekcy.v1alpha2.ListUnreadResp', null, global);
goog.exportSymbol('proto.api.grekcy.v1alpha2.ParseUrlResp', null, global);
goog.exportSymbol('proto.api.grekcy.v1alpha2.ParseUrlResp.Status', null, global);
goog.exportSymbol('proto.api.grekcy.v1alpha2.Subscription', null, global);
goog.exportSymbol('proto.api.grekcy.v1alpha2.SubscriptionConfig', null, global);
goog.exportSymbol('proto.api.grekcy.v1alpha2.Unread', null, global);
goog.exportSymbol('proto.api.grekcy.v1alpha2.UpdateSubscriptionConfigReq', null, global);
goog.exportSymbol('proto.api.grekcy.v1alpha2.UpdateUserSettingsReq', null, global);
goog.exportSymbol('proto.api.grekcy.v1alpha2.UserSettings', null, global);
goog.exportSymbol('proto.api.grekcy.v1alpha2.UserSettings.Setting', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.grekcy.v1alpha2.GoogleLoginReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.grekcy.v1alpha2.GoogleLoginReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.grekcy.v1alpha2.GoogleLoginReq.displayName = 'proto.api.grekcy.v1alpha2.GoogleLoginReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.grekcy.v1alpha2.UserSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.grekcy.v1alpha2.UserSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.grekcy.v1alpha2.UserSettings.displayName = 'proto.api.grekcy.v1alpha2.UserSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.grekcy.v1alpha2.UpdateUserSettingsReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.grekcy.v1alpha2.UpdateUserSettingsReq.repeatedFields_, null);
};
goog.inherits(proto.api.grekcy.v1alpha2.UpdateUserSettingsReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.grekcy.v1alpha2.UpdateUserSettingsReq.displayName = 'proto.api.grekcy.v1alpha2.UpdateUserSettingsReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.grekcy.v1alpha2.ListSubscriptionResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.grekcy.v1alpha2.ListSubscriptionResp.repeatedFields_, null);
};
goog.inherits(proto.api.grekcy.v1alpha2.ListSubscriptionResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.grekcy.v1alpha2.ListSubscriptionResp.displayName = 'proto.api.grekcy.v1alpha2.ListSubscriptionResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.grekcy.v1alpha2.Subscription = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.grekcy.v1alpha2.Subscription, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.grekcy.v1alpha2.Subscription.displayName = 'proto.api.grekcy.v1alpha2.Subscription';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.grekcy.v1alpha2.ParseUrlResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.grekcy.v1alpha2.ParseUrlResp.repeatedFields_, null);
};
goog.inherits(proto.api.grekcy.v1alpha2.ParseUrlResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.grekcy.v1alpha2.ParseUrlResp.displayName = 'proto.api.grekcy.v1alpha2.ParseUrlResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.grekcy.v1alpha2.Feed = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.grekcy.v1alpha2.Feed.repeatedFields_, null);
};
goog.inherits(proto.api.grekcy.v1alpha2.Feed, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.grekcy.v1alpha2.Feed.displayName = 'proto.api.grekcy.v1alpha2.Feed';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.grekcy.v1alpha2.SubscriptionConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.grekcy.v1alpha2.SubscriptionConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.grekcy.v1alpha2.SubscriptionConfig.displayName = 'proto.api.grekcy.v1alpha2.SubscriptionConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.grekcy.v1alpha2.ListSubscriptionConfigResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.grekcy.v1alpha2.ListSubscriptionConfigResp.repeatedFields_, null);
};
goog.inherits(proto.api.grekcy.v1alpha2.ListSubscriptionConfigResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.grekcy.v1alpha2.ListSubscriptionConfigResp.displayName = 'proto.api.grekcy.v1alpha2.ListSubscriptionConfigResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.grekcy.v1alpha2.UpdateSubscriptionConfigReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.grekcy.v1alpha2.UpdateSubscriptionConfigReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.grekcy.v1alpha2.UpdateSubscriptionConfigReq.displayName = 'proto.api.grekcy.v1alpha2.UpdateSubscriptionConfigReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.grekcy.v1alpha2.Unread = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.grekcy.v1alpha2.Unread, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.grekcy.v1alpha2.Unread.displayName = 'proto.api.grekcy.v1alpha2.Unread';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.grekcy.v1alpha2.ListUnreadResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.grekcy.v1alpha2.ListUnreadResp.repeatedFields_, null);
};
goog.inherits(proto.api.grekcy.v1alpha2.ListUnreadResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.grekcy.v1alpha2.ListUnreadResp.displayName = 'proto.api.grekcy.v1alpha2.ListUnreadResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.grekcy.v1alpha2.ListArticleReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.grekcy.v1alpha2.ListArticleReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.grekcy.v1alpha2.ListArticleReq.displayName = 'proto.api.grekcy.v1alpha2.ListArticleReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.grekcy.v1alpha2.Article = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.grekcy.v1alpha2.Article, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.grekcy.v1alpha2.Article.displayName = 'proto.api.grekcy.v1alpha2.Article';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.grekcy.v1alpha2.ListArticleResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.grekcy.v1alpha2.ListArticleResp.repeatedFields_, null);
};
goog.inherits(proto.api.grekcy.v1alpha2.ListArticleResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.grekcy.v1alpha2.ListArticleResp.displayName = 'proto.api.grekcy.v1alpha2.ListArticleResp';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.grekcy.v1alpha2.GoogleLoginReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.grekcy.v1alpha2.GoogleLoginReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.grekcy.v1alpha2.GoogleLoginReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.grekcy.v1alpha2.GoogleLoginReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    credential: jspb.Message.getFieldWithDefault(msg, 1, ""),
    clientId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    extra: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.grekcy.v1alpha2.GoogleLoginReq}
 */
proto.api.grekcy.v1alpha2.GoogleLoginReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.grekcy.v1alpha2.GoogleLoginReq;
  return proto.api.grekcy.v1alpha2.GoogleLoginReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.grekcy.v1alpha2.GoogleLoginReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.grekcy.v1alpha2.GoogleLoginReq}
 */
proto.api.grekcy.v1alpha2.GoogleLoginReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCredential(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setExtra(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.grekcy.v1alpha2.GoogleLoginReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.grekcy.v1alpha2.GoogleLoginReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.grekcy.v1alpha2.GoogleLoginReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.grekcy.v1alpha2.GoogleLoginReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCredential();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getClientId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getExtra();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string credential = 1;
 * @return {string}
 */
proto.api.grekcy.v1alpha2.GoogleLoginReq.prototype.getCredential = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.grekcy.v1alpha2.GoogleLoginReq} returns this
 */
proto.api.grekcy.v1alpha2.GoogleLoginReq.prototype.setCredential = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string client_id = 2;
 * @return {string}
 */
proto.api.grekcy.v1alpha2.GoogleLoginReq.prototype.getClientId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.grekcy.v1alpha2.GoogleLoginReq} returns this
 */
proto.api.grekcy.v1alpha2.GoogleLoginReq.prototype.setClientId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string extra = 3;
 * @return {string}
 */
proto.api.grekcy.v1alpha2.GoogleLoginReq.prototype.getExtra = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.grekcy.v1alpha2.GoogleLoginReq} returns this
 */
proto.api.grekcy.v1alpha2.GoogleLoginReq.prototype.setExtra = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.grekcy.v1alpha2.UserSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.api.grekcy.v1alpha2.UserSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.grekcy.v1alpha2.UserSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.grekcy.v1alpha2.UserSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    getpocketToken: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.grekcy.v1alpha2.UserSettings}
 */
proto.api.grekcy.v1alpha2.UserSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.grekcy.v1alpha2.UserSettings;
  return proto.api.grekcy.v1alpha2.UserSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.grekcy.v1alpha2.UserSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.grekcy.v1alpha2.UserSettings}
 */
proto.api.grekcy.v1alpha2.UserSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setGetpocketToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.grekcy.v1alpha2.UserSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.grekcy.v1alpha2.UserSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.grekcy.v1alpha2.UserSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.grekcy.v1alpha2.UserSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGetpocketToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.api.grekcy.v1alpha2.UserSettings.Setting = {
  POCKET_TOKEN: 0
};

/**
 * optional string getpocket_token = 1;
 * @return {string}
 */
proto.api.grekcy.v1alpha2.UserSettings.prototype.getGetpocketToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.grekcy.v1alpha2.UserSettings} returns this
 */
proto.api.grekcy.v1alpha2.UserSettings.prototype.setGetpocketToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.grekcy.v1alpha2.UpdateUserSettingsReq.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.grekcy.v1alpha2.UpdateUserSettingsReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.grekcy.v1alpha2.UpdateUserSettingsReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.grekcy.v1alpha2.UpdateUserSettingsReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.grekcy.v1alpha2.UpdateUserSettingsReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    fieldsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    settings: (f = msg.getSettings()) && proto.api.grekcy.v1alpha2.UserSettings.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.grekcy.v1alpha2.UpdateUserSettingsReq}
 */
proto.api.grekcy.v1alpha2.UpdateUserSettingsReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.grekcy.v1alpha2.UpdateUserSettingsReq;
  return proto.api.grekcy.v1alpha2.UpdateUserSettingsReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.grekcy.v1alpha2.UpdateUserSettingsReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.grekcy.v1alpha2.UpdateUserSettingsReq}
 */
proto.api.grekcy.v1alpha2.UpdateUserSettingsReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<!proto.api.grekcy.v1alpha2.UserSettings.Setting>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addFields(values[i]);
      }
      break;
    case 2:
      var value = new proto.api.grekcy.v1alpha2.UserSettings;
      reader.readMessage(value,proto.api.grekcy.v1alpha2.UserSettings.deserializeBinaryFromReader);
      msg.setSettings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.grekcy.v1alpha2.UpdateUserSettingsReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.grekcy.v1alpha2.UpdateUserSettingsReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.grekcy.v1alpha2.UpdateUserSettingsReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.grekcy.v1alpha2.UpdateUserSettingsReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFieldsList();
  if (f.length > 0) {
    writer.writePackedEnum(
      1,
      f
    );
  }
  f = message.getSettings();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.api.grekcy.v1alpha2.UserSettings.serializeBinaryToWriter
    );
  }
};


/**
 * repeated UserSettings.Setting fields = 1;
 * @return {!Array<!proto.api.grekcy.v1alpha2.UserSettings.Setting>}
 */
proto.api.grekcy.v1alpha2.UpdateUserSettingsReq.prototype.getFieldsList = function() {
  return /** @type {!Array<!proto.api.grekcy.v1alpha2.UserSettings.Setting>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<!proto.api.grekcy.v1alpha2.UserSettings.Setting>} value
 * @return {!proto.api.grekcy.v1alpha2.UpdateUserSettingsReq} returns this
 */
proto.api.grekcy.v1alpha2.UpdateUserSettingsReq.prototype.setFieldsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {!proto.api.grekcy.v1alpha2.UserSettings.Setting} value
 * @param {number=} opt_index
 * @return {!proto.api.grekcy.v1alpha2.UpdateUserSettingsReq} returns this
 */
proto.api.grekcy.v1alpha2.UpdateUserSettingsReq.prototype.addFields = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.grekcy.v1alpha2.UpdateUserSettingsReq} returns this
 */
proto.api.grekcy.v1alpha2.UpdateUserSettingsReq.prototype.clearFieldsList = function() {
  return this.setFieldsList([]);
};


/**
 * optional UserSettings settings = 2;
 * @return {?proto.api.grekcy.v1alpha2.UserSettings}
 */
proto.api.grekcy.v1alpha2.UpdateUserSettingsReq.prototype.getSettings = function() {
  return /** @type{?proto.api.grekcy.v1alpha2.UserSettings} */ (
    jspb.Message.getWrapperField(this, proto.api.grekcy.v1alpha2.UserSettings, 2));
};


/**
 * @param {?proto.api.grekcy.v1alpha2.UserSettings|undefined} value
 * @return {!proto.api.grekcy.v1alpha2.UpdateUserSettingsReq} returns this
*/
proto.api.grekcy.v1alpha2.UpdateUserSettingsReq.prototype.setSettings = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.grekcy.v1alpha2.UpdateUserSettingsReq} returns this
 */
proto.api.grekcy.v1alpha2.UpdateUserSettingsReq.prototype.clearSettings = function() {
  return this.setSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.grekcy.v1alpha2.UpdateUserSettingsReq.prototype.hasSettings = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.grekcy.v1alpha2.ListSubscriptionResp.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.grekcy.v1alpha2.ListSubscriptionResp.prototype.toObject = function(opt_includeInstance) {
  return proto.api.grekcy.v1alpha2.ListSubscriptionResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.grekcy.v1alpha2.ListSubscriptionResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.grekcy.v1alpha2.ListSubscriptionResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.api.grekcy.v1alpha2.Subscription.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.grekcy.v1alpha2.ListSubscriptionResp}
 */
proto.api.grekcy.v1alpha2.ListSubscriptionResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.grekcy.v1alpha2.ListSubscriptionResp;
  return proto.api.grekcy.v1alpha2.ListSubscriptionResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.grekcy.v1alpha2.ListSubscriptionResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.grekcy.v1alpha2.ListSubscriptionResp}
 */
proto.api.grekcy.v1alpha2.ListSubscriptionResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.grekcy.v1alpha2.Subscription;
      reader.readMessage(value,proto.api.grekcy.v1alpha2.Subscription.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.grekcy.v1alpha2.ListSubscriptionResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.grekcy.v1alpha2.ListSubscriptionResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.grekcy.v1alpha2.ListSubscriptionResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.grekcy.v1alpha2.ListSubscriptionResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.api.grekcy.v1alpha2.Subscription.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Subscription items = 1;
 * @return {!Array<!proto.api.grekcy.v1alpha2.Subscription>}
 */
proto.api.grekcy.v1alpha2.ListSubscriptionResp.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.api.grekcy.v1alpha2.Subscription>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.grekcy.v1alpha2.Subscription, 1));
};


/**
 * @param {!Array<!proto.api.grekcy.v1alpha2.Subscription>} value
 * @return {!proto.api.grekcy.v1alpha2.ListSubscriptionResp} returns this
*/
proto.api.grekcy.v1alpha2.ListSubscriptionResp.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.api.grekcy.v1alpha2.Subscription=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.grekcy.v1alpha2.Subscription}
 */
proto.api.grekcy.v1alpha2.ListSubscriptionResp.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.api.grekcy.v1alpha2.Subscription, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.grekcy.v1alpha2.ListSubscriptionResp} returns this
 */
proto.api.grekcy.v1alpha2.ListSubscriptionResp.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.grekcy.v1alpha2.Subscription.prototype.toObject = function(opt_includeInstance) {
  return proto.api.grekcy.v1alpha2.Subscription.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.grekcy.v1alpha2.Subscription} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.grekcy.v1alpha2.Subscription.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    url: jspb.Message.getFieldWithDefault(msg, 3, ""),
    description: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.grekcy.v1alpha2.Subscription}
 */
proto.api.grekcy.v1alpha2.Subscription.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.grekcy.v1alpha2.Subscription;
  return proto.api.grekcy.v1alpha2.Subscription.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.grekcy.v1alpha2.Subscription} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.grekcy.v1alpha2.Subscription}
 */
proto.api.grekcy.v1alpha2.Subscription.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.grekcy.v1alpha2.Subscription.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.grekcy.v1alpha2.Subscription.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.grekcy.v1alpha2.Subscription} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.grekcy.v1alpha2.Subscription.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.api.grekcy.v1alpha2.Subscription.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.grekcy.v1alpha2.Subscription} returns this
 */
proto.api.grekcy.v1alpha2.Subscription.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.api.grekcy.v1alpha2.Subscription.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.grekcy.v1alpha2.Subscription} returns this
 */
proto.api.grekcy.v1alpha2.Subscription.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string url = 3;
 * @return {string}
 */
proto.api.grekcy.v1alpha2.Subscription.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.grekcy.v1alpha2.Subscription} returns this
 */
proto.api.grekcy.v1alpha2.Subscription.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string description = 4;
 * @return {string}
 */
proto.api.grekcy.v1alpha2.Subscription.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.grekcy.v1alpha2.Subscription} returns this
 */
proto.api.grekcy.v1alpha2.Subscription.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.grekcy.v1alpha2.ParseUrlResp.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.grekcy.v1alpha2.ParseUrlResp.prototype.toObject = function(opt_includeInstance) {
  return proto.api.grekcy.v1alpha2.ParseUrlResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.grekcy.v1alpha2.ParseUrlResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.grekcy.v1alpha2.ParseUrlResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    message: jspb.Message.getFieldWithDefault(msg, 2, ""),
    feed: (f = msg.getFeed()) && proto.api.grekcy.v1alpha2.Feed.toObject(includeInstance, f),
    feedsList: jspb.Message.toObjectList(msg.getFeedsList(),
    proto.api.grekcy.v1alpha2.Feed.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.grekcy.v1alpha2.ParseUrlResp}
 */
proto.api.grekcy.v1alpha2.ParseUrlResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.grekcy.v1alpha2.ParseUrlResp;
  return proto.api.grekcy.v1alpha2.ParseUrlResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.grekcy.v1alpha2.ParseUrlResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.grekcy.v1alpha2.ParseUrlResp}
 */
proto.api.grekcy.v1alpha2.ParseUrlResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.api.grekcy.v1alpha2.ParseUrlResp.Status} */ (reader.readEnum());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 3:
      var value = new proto.api.grekcy.v1alpha2.Feed;
      reader.readMessage(value,proto.api.grekcy.v1alpha2.Feed.deserializeBinaryFromReader);
      msg.setFeed(value);
      break;
    case 4:
      var value = new proto.api.grekcy.v1alpha2.Feed;
      reader.readMessage(value,proto.api.grekcy.v1alpha2.Feed.deserializeBinaryFromReader);
      msg.addFeeds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.grekcy.v1alpha2.ParseUrlResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.grekcy.v1alpha2.ParseUrlResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.grekcy.v1alpha2.ParseUrlResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.grekcy.v1alpha2.ParseUrlResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFeed();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.api.grekcy.v1alpha2.Feed.serializeBinaryToWriter
    );
  }
  f = message.getFeedsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.api.grekcy.v1alpha2.Feed.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.api.grekcy.v1alpha2.ParseUrlResp.Status = {
  SUCCESS: 0,
  ERROR: 1,
  FEED_NOT_DETECTED: 2,
  FEED_NOT_FOUND: 3,
  MULTIPLE_FEED: 4
};

/**
 * optional Status code = 1;
 * @return {!proto.api.grekcy.v1alpha2.ParseUrlResp.Status}
 */
proto.api.grekcy.v1alpha2.ParseUrlResp.prototype.getCode = function() {
  return /** @type {!proto.api.grekcy.v1alpha2.ParseUrlResp.Status} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.api.grekcy.v1alpha2.ParseUrlResp.Status} value
 * @return {!proto.api.grekcy.v1alpha2.ParseUrlResp} returns this
 */
proto.api.grekcy.v1alpha2.ParseUrlResp.prototype.setCode = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.api.grekcy.v1alpha2.ParseUrlResp.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.grekcy.v1alpha2.ParseUrlResp} returns this
 */
proto.api.grekcy.v1alpha2.ParseUrlResp.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Feed feed = 3;
 * @return {?proto.api.grekcy.v1alpha2.Feed}
 */
proto.api.grekcy.v1alpha2.ParseUrlResp.prototype.getFeed = function() {
  return /** @type{?proto.api.grekcy.v1alpha2.Feed} */ (
    jspb.Message.getWrapperField(this, proto.api.grekcy.v1alpha2.Feed, 3));
};


/**
 * @param {?proto.api.grekcy.v1alpha2.Feed|undefined} value
 * @return {!proto.api.grekcy.v1alpha2.ParseUrlResp} returns this
*/
proto.api.grekcy.v1alpha2.ParseUrlResp.prototype.setFeed = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.grekcy.v1alpha2.ParseUrlResp} returns this
 */
proto.api.grekcy.v1alpha2.ParseUrlResp.prototype.clearFeed = function() {
  return this.setFeed(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.grekcy.v1alpha2.ParseUrlResp.prototype.hasFeed = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated Feed feeds = 4;
 * @return {!Array<!proto.api.grekcy.v1alpha2.Feed>}
 */
proto.api.grekcy.v1alpha2.ParseUrlResp.prototype.getFeedsList = function() {
  return /** @type{!Array<!proto.api.grekcy.v1alpha2.Feed>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.grekcy.v1alpha2.Feed, 4));
};


/**
 * @param {!Array<!proto.api.grekcy.v1alpha2.Feed>} value
 * @return {!proto.api.grekcy.v1alpha2.ParseUrlResp} returns this
*/
proto.api.grekcy.v1alpha2.ParseUrlResp.prototype.setFeedsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.api.grekcy.v1alpha2.Feed=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.grekcy.v1alpha2.Feed}
 */
proto.api.grekcy.v1alpha2.ParseUrlResp.prototype.addFeeds = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.api.grekcy.v1alpha2.Feed, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.grekcy.v1alpha2.ParseUrlResp} returns this
 */
proto.api.grekcy.v1alpha2.ParseUrlResp.prototype.clearFeedsList = function() {
  return this.setFeedsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.grekcy.v1alpha2.Feed.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.grekcy.v1alpha2.Feed.prototype.toObject = function(opt_includeInstance) {
  return proto.api.grekcy.v1alpha2.Feed.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.grekcy.v1alpha2.Feed} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.grekcy.v1alpha2.Feed.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: jspb.Message.getFieldWithDefault(msg, 1, ""),
    link: jspb.Message.getFieldWithDefault(msg, 2, ""),
    itemsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.grekcy.v1alpha2.Feed}
 */
proto.api.grekcy.v1alpha2.Feed.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.grekcy.v1alpha2.Feed;
  return proto.api.grekcy.v1alpha2.Feed.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.grekcy.v1alpha2.Feed} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.grekcy.v1alpha2.Feed}
 */
proto.api.grekcy.v1alpha2.Feed.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLink(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.grekcy.v1alpha2.Feed.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.grekcy.v1alpha2.Feed.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.grekcy.v1alpha2.Feed} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.grekcy.v1alpha2.Feed.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLink();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
};


/**
 * optional string title = 1;
 * @return {string}
 */
proto.api.grekcy.v1alpha2.Feed.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.grekcy.v1alpha2.Feed} returns this
 */
proto.api.grekcy.v1alpha2.Feed.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string link = 2;
 * @return {string}
 */
proto.api.grekcy.v1alpha2.Feed.prototype.getLink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.grekcy.v1alpha2.Feed} returns this
 */
proto.api.grekcy.v1alpha2.Feed.prototype.setLink = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string items = 3;
 * @return {!Array<string>}
 */
proto.api.grekcy.v1alpha2.Feed.prototype.getItemsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.api.grekcy.v1alpha2.Feed} returns this
 */
proto.api.grekcy.v1alpha2.Feed.prototype.setItemsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.api.grekcy.v1alpha2.Feed} returns this
 */
proto.api.grekcy.v1alpha2.Feed.prototype.addItems = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.grekcy.v1alpha2.Feed} returns this
 */
proto.api.grekcy.v1alpha2.Feed.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.grekcy.v1alpha2.SubscriptionConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.api.grekcy.v1alpha2.SubscriptionConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.grekcy.v1alpha2.SubscriptionConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.grekcy.v1alpha2.SubscriptionConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    feedId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    openReadability: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.grekcy.v1alpha2.SubscriptionConfig}
 */
proto.api.grekcy.v1alpha2.SubscriptionConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.grekcy.v1alpha2.SubscriptionConfig;
  return proto.api.grekcy.v1alpha2.SubscriptionConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.grekcy.v1alpha2.SubscriptionConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.grekcy.v1alpha2.SubscriptionConfig}
 */
proto.api.grekcy.v1alpha2.SubscriptionConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFeedId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOpenReadability(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.grekcy.v1alpha2.SubscriptionConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.grekcy.v1alpha2.SubscriptionConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.grekcy.v1alpha2.SubscriptionConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.grekcy.v1alpha2.SubscriptionConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFeedId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOpenReadability();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string feed_id = 1;
 * @return {string}
 */
proto.api.grekcy.v1alpha2.SubscriptionConfig.prototype.getFeedId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.grekcy.v1alpha2.SubscriptionConfig} returns this
 */
proto.api.grekcy.v1alpha2.SubscriptionConfig.prototype.setFeedId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool open_readability = 2;
 * @return {boolean}
 */
proto.api.grekcy.v1alpha2.SubscriptionConfig.prototype.getOpenReadability = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.grekcy.v1alpha2.SubscriptionConfig} returns this
 */
proto.api.grekcy.v1alpha2.SubscriptionConfig.prototype.setOpenReadability = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.grekcy.v1alpha2.ListSubscriptionConfigResp.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.grekcy.v1alpha2.ListSubscriptionConfigResp.prototype.toObject = function(opt_includeInstance) {
  return proto.api.grekcy.v1alpha2.ListSubscriptionConfigResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.grekcy.v1alpha2.ListSubscriptionConfigResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.grekcy.v1alpha2.ListSubscriptionConfigResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.api.grekcy.v1alpha2.SubscriptionConfig.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.grekcy.v1alpha2.ListSubscriptionConfigResp}
 */
proto.api.grekcy.v1alpha2.ListSubscriptionConfigResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.grekcy.v1alpha2.ListSubscriptionConfigResp;
  return proto.api.grekcy.v1alpha2.ListSubscriptionConfigResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.grekcy.v1alpha2.ListSubscriptionConfigResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.grekcy.v1alpha2.ListSubscriptionConfigResp}
 */
proto.api.grekcy.v1alpha2.ListSubscriptionConfigResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.grekcy.v1alpha2.SubscriptionConfig;
      reader.readMessage(value,proto.api.grekcy.v1alpha2.SubscriptionConfig.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.grekcy.v1alpha2.ListSubscriptionConfigResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.grekcy.v1alpha2.ListSubscriptionConfigResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.grekcy.v1alpha2.ListSubscriptionConfigResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.grekcy.v1alpha2.ListSubscriptionConfigResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.api.grekcy.v1alpha2.SubscriptionConfig.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SubscriptionConfig items = 1;
 * @return {!Array<!proto.api.grekcy.v1alpha2.SubscriptionConfig>}
 */
proto.api.grekcy.v1alpha2.ListSubscriptionConfigResp.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.api.grekcy.v1alpha2.SubscriptionConfig>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.grekcy.v1alpha2.SubscriptionConfig, 1));
};


/**
 * @param {!Array<!proto.api.grekcy.v1alpha2.SubscriptionConfig>} value
 * @return {!proto.api.grekcy.v1alpha2.ListSubscriptionConfigResp} returns this
*/
proto.api.grekcy.v1alpha2.ListSubscriptionConfigResp.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.api.grekcy.v1alpha2.SubscriptionConfig=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.grekcy.v1alpha2.SubscriptionConfig}
 */
proto.api.grekcy.v1alpha2.ListSubscriptionConfigResp.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.api.grekcy.v1alpha2.SubscriptionConfig, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.grekcy.v1alpha2.ListSubscriptionConfigResp} returns this
 */
proto.api.grekcy.v1alpha2.ListSubscriptionConfigResp.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.grekcy.v1alpha2.UpdateSubscriptionConfigReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.grekcy.v1alpha2.UpdateSubscriptionConfigReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.grekcy.v1alpha2.UpdateSubscriptionConfigReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.grekcy.v1alpha2.UpdateSubscriptionConfigReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    feedId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    openReadability: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.grekcy.v1alpha2.UpdateSubscriptionConfigReq}
 */
proto.api.grekcy.v1alpha2.UpdateSubscriptionConfigReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.grekcy.v1alpha2.UpdateSubscriptionConfigReq;
  return proto.api.grekcy.v1alpha2.UpdateSubscriptionConfigReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.grekcy.v1alpha2.UpdateSubscriptionConfigReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.grekcy.v1alpha2.UpdateSubscriptionConfigReq}
 */
proto.api.grekcy.v1alpha2.UpdateSubscriptionConfigReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFeedId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOpenReadability(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.grekcy.v1alpha2.UpdateSubscriptionConfigReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.grekcy.v1alpha2.UpdateSubscriptionConfigReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.grekcy.v1alpha2.UpdateSubscriptionConfigReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.grekcy.v1alpha2.UpdateSubscriptionConfigReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFeedId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOpenReadability();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string feed_id = 1;
 * @return {string}
 */
proto.api.grekcy.v1alpha2.UpdateSubscriptionConfigReq.prototype.getFeedId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.grekcy.v1alpha2.UpdateSubscriptionConfigReq} returns this
 */
proto.api.grekcy.v1alpha2.UpdateSubscriptionConfigReq.prototype.setFeedId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool open_readability = 2;
 * @return {boolean}
 */
proto.api.grekcy.v1alpha2.UpdateSubscriptionConfigReq.prototype.getOpenReadability = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.grekcy.v1alpha2.UpdateSubscriptionConfigReq} returns this
 */
proto.api.grekcy.v1alpha2.UpdateSubscriptionConfigReq.prototype.setOpenReadability = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.grekcy.v1alpha2.Unread.prototype.toObject = function(opt_includeInstance) {
  return proto.api.grekcy.v1alpha2.Unread.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.grekcy.v1alpha2.Unread} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.grekcy.v1alpha2.Unread.toObject = function(includeInstance, msg) {
  var f, obj = {
    feedId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    count: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.grekcy.v1alpha2.Unread}
 */
proto.api.grekcy.v1alpha2.Unread.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.grekcy.v1alpha2.Unread;
  return proto.api.grekcy.v1alpha2.Unread.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.grekcy.v1alpha2.Unread} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.grekcy.v1alpha2.Unread}
 */
proto.api.grekcy.v1alpha2.Unread.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFeedId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.grekcy.v1alpha2.Unread.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.grekcy.v1alpha2.Unread.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.grekcy.v1alpha2.Unread} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.grekcy.v1alpha2.Unread.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFeedId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string feed_id = 1;
 * @return {string}
 */
proto.api.grekcy.v1alpha2.Unread.prototype.getFeedId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.grekcy.v1alpha2.Unread} returns this
 */
proto.api.grekcy.v1alpha2.Unread.prototype.setFeedId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 count = 2;
 * @return {number}
 */
proto.api.grekcy.v1alpha2.Unread.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.grekcy.v1alpha2.Unread} returns this
 */
proto.api.grekcy.v1alpha2.Unread.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.grekcy.v1alpha2.ListUnreadResp.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.grekcy.v1alpha2.ListUnreadResp.prototype.toObject = function(opt_includeInstance) {
  return proto.api.grekcy.v1alpha2.ListUnreadResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.grekcy.v1alpha2.ListUnreadResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.grekcy.v1alpha2.ListUnreadResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.api.grekcy.v1alpha2.Unread.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.grekcy.v1alpha2.ListUnreadResp}
 */
proto.api.grekcy.v1alpha2.ListUnreadResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.grekcy.v1alpha2.ListUnreadResp;
  return proto.api.grekcy.v1alpha2.ListUnreadResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.grekcy.v1alpha2.ListUnreadResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.grekcy.v1alpha2.ListUnreadResp}
 */
proto.api.grekcy.v1alpha2.ListUnreadResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.grekcy.v1alpha2.Unread;
      reader.readMessage(value,proto.api.grekcy.v1alpha2.Unread.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.grekcy.v1alpha2.ListUnreadResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.grekcy.v1alpha2.ListUnreadResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.grekcy.v1alpha2.ListUnreadResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.grekcy.v1alpha2.ListUnreadResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.api.grekcy.v1alpha2.Unread.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Unread items = 1;
 * @return {!Array<!proto.api.grekcy.v1alpha2.Unread>}
 */
proto.api.grekcy.v1alpha2.ListUnreadResp.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.api.grekcy.v1alpha2.Unread>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.grekcy.v1alpha2.Unread, 1));
};


/**
 * @param {!Array<!proto.api.grekcy.v1alpha2.Unread>} value
 * @return {!proto.api.grekcy.v1alpha2.ListUnreadResp} returns this
*/
proto.api.grekcy.v1alpha2.ListUnreadResp.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.api.grekcy.v1alpha2.Unread=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.grekcy.v1alpha2.Unread}
 */
proto.api.grekcy.v1alpha2.ListUnreadResp.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.api.grekcy.v1alpha2.Unread, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.grekcy.v1alpha2.ListUnreadResp} returns this
 */
proto.api.grekcy.v1alpha2.ListUnreadResp.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.grekcy.v1alpha2.ListArticleReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.grekcy.v1alpha2.ListArticleReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.grekcy.v1alpha2.ListArticleReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.grekcy.v1alpha2.ListArticleReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    feedId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    mode: jspb.Message.getFieldWithDefault(msg, 2, 0),
    order: jspb.Message.getFieldWithDefault(msg, 3, 0),
    count: jspb.Message.getFieldWithDefault(msg, 4, 0),
    next: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.grekcy.v1alpha2.ListArticleReq}
 */
proto.api.grekcy.v1alpha2.ListArticleReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.grekcy.v1alpha2.ListArticleReq;
  return proto.api.grekcy.v1alpha2.ListArticleReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.grekcy.v1alpha2.ListArticleReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.grekcy.v1alpha2.ListArticleReq}
 */
proto.api.grekcy.v1alpha2.ListArticleReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFeedId(value);
      break;
    case 2:
      var value = /** @type {!proto.api.grekcy.v1alpha2.ListArticleReq.Mode} */ (reader.readEnum());
      msg.setMode(value);
      break;
    case 3:
      var value = /** @type {!proto.api.grekcy.v1alpha2.ListOrder} */ (reader.readEnum());
      msg.setOrder(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCount(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setNext(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.grekcy.v1alpha2.ListArticleReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.grekcy.v1alpha2.ListArticleReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.grekcy.v1alpha2.ListArticleReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.grekcy.v1alpha2.ListArticleReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFeedId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMode();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = /** @type {!proto.api.grekcy.v1alpha2.ListOrder} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.api.grekcy.v1alpha2.ListArticleReq.Mode = {
  SNIPPT: 0,
  FULL: 1
};

/**
 * optional string feed_id = 1;
 * @return {string}
 */
proto.api.grekcy.v1alpha2.ListArticleReq.prototype.getFeedId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.grekcy.v1alpha2.ListArticleReq} returns this
 */
proto.api.grekcy.v1alpha2.ListArticleReq.prototype.setFeedId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Mode mode = 2;
 * @return {!proto.api.grekcy.v1alpha2.ListArticleReq.Mode}
 */
proto.api.grekcy.v1alpha2.ListArticleReq.prototype.getMode = function() {
  return /** @type {!proto.api.grekcy.v1alpha2.ListArticleReq.Mode} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.api.grekcy.v1alpha2.ListArticleReq.Mode} value
 * @return {!proto.api.grekcy.v1alpha2.ListArticleReq} returns this
 */
proto.api.grekcy.v1alpha2.ListArticleReq.prototype.setMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional ListOrder order = 3;
 * @return {!proto.api.grekcy.v1alpha2.ListOrder}
 */
proto.api.grekcy.v1alpha2.ListArticleReq.prototype.getOrder = function() {
  return /** @type {!proto.api.grekcy.v1alpha2.ListOrder} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.api.grekcy.v1alpha2.ListOrder} value
 * @return {!proto.api.grekcy.v1alpha2.ListArticleReq} returns this
 */
proto.api.grekcy.v1alpha2.ListArticleReq.prototype.setOrder = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.api.grekcy.v1alpha2.ListArticleReq} returns this
 */
proto.api.grekcy.v1alpha2.ListArticleReq.prototype.clearOrder = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.grekcy.v1alpha2.ListArticleReq.prototype.hasOrder = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional uint32 count = 4;
 * @return {number}
 */
proto.api.grekcy.v1alpha2.ListArticleReq.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.grekcy.v1alpha2.ListArticleReq} returns this
 */
proto.api.grekcy.v1alpha2.ListArticleReq.prototype.setCount = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.api.grekcy.v1alpha2.ListArticleReq} returns this
 */
proto.api.grekcy.v1alpha2.ListArticleReq.prototype.clearCount = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.grekcy.v1alpha2.ListArticleReq.prototype.hasCount = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string next = 5;
 * @return {string}
 */
proto.api.grekcy.v1alpha2.ListArticleReq.prototype.getNext = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.grekcy.v1alpha2.ListArticleReq} returns this
 */
proto.api.grekcy.v1alpha2.ListArticleReq.prototype.setNext = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.api.grekcy.v1alpha2.ListArticleReq} returns this
 */
proto.api.grekcy.v1alpha2.ListArticleReq.prototype.clearNext = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.grekcy.v1alpha2.ListArticleReq.prototype.hasNext = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.grekcy.v1alpha2.Article.prototype.toObject = function(opt_includeInstance) {
  return proto.api.grekcy.v1alpha2.Article.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.grekcy.v1alpha2.Article} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.grekcy.v1alpha2.Article.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    feedId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    title: jspb.Message.getFieldWithDefault(msg, 3, ""),
    summary: jspb.Message.getFieldWithDefault(msg, 4, ""),
    content: jspb.Message.getFieldWithDefault(msg, 5, ""),
    url: jspb.Message.getFieldWithDefault(msg, 6, ""),
    author: jspb.Message.getFieldWithDefault(msg, 7, ""),
    publishedAt: (f = msg.getPublishedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    crawledAt: (f = msg.getCrawledAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    readed: jspb.Message.getBooleanFieldWithDefault(msg, 11, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.grekcy.v1alpha2.Article}
 */
proto.api.grekcy.v1alpha2.Article.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.grekcy.v1alpha2.Article;
  return proto.api.grekcy.v1alpha2.Article.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.grekcy.v1alpha2.Article} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.grekcy.v1alpha2.Article}
 */
proto.api.grekcy.v1alpha2.Article.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFeedId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSummary(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setContent(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthor(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setPublishedAt(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 10:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCrawledAt(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReaded(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.grekcy.v1alpha2.Article.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.grekcy.v1alpha2.Article.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.grekcy.v1alpha2.Article} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.grekcy.v1alpha2.Article.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFeedId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSummary();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getContent();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getAuthor();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getPublishedAt();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCrawledAt();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getReaded();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.api.grekcy.v1alpha2.Article.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.grekcy.v1alpha2.Article} returns this
 */
proto.api.grekcy.v1alpha2.Article.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string feed_id = 2;
 * @return {string}
 */
proto.api.grekcy.v1alpha2.Article.prototype.getFeedId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.grekcy.v1alpha2.Article} returns this
 */
proto.api.grekcy.v1alpha2.Article.prototype.setFeedId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string title = 3;
 * @return {string}
 */
proto.api.grekcy.v1alpha2.Article.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.grekcy.v1alpha2.Article} returns this
 */
proto.api.grekcy.v1alpha2.Article.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string summary = 4;
 * @return {string}
 */
proto.api.grekcy.v1alpha2.Article.prototype.getSummary = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.grekcy.v1alpha2.Article} returns this
 */
proto.api.grekcy.v1alpha2.Article.prototype.setSummary = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string content = 5;
 * @return {string}
 */
proto.api.grekcy.v1alpha2.Article.prototype.getContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.grekcy.v1alpha2.Article} returns this
 */
proto.api.grekcy.v1alpha2.Article.prototype.setContent = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string url = 6;
 * @return {string}
 */
proto.api.grekcy.v1alpha2.Article.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.grekcy.v1alpha2.Article} returns this
 */
proto.api.grekcy.v1alpha2.Article.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string author = 7;
 * @return {string}
 */
proto.api.grekcy.v1alpha2.Article.prototype.getAuthor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.grekcy.v1alpha2.Article} returns this
 */
proto.api.grekcy.v1alpha2.Article.prototype.setAuthor = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional google.protobuf.Timestamp published_at = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.api.grekcy.v1alpha2.Article.prototype.getPublishedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.api.grekcy.v1alpha2.Article} returns this
*/
proto.api.grekcy.v1alpha2.Article.prototype.setPublishedAt = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.grekcy.v1alpha2.Article} returns this
 */
proto.api.grekcy.v1alpha2.Article.prototype.clearPublishedAt = function() {
  return this.setPublishedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.grekcy.v1alpha2.Article.prototype.hasPublishedAt = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.Timestamp updated_at = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.api.grekcy.v1alpha2.Article.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.api.grekcy.v1alpha2.Article} returns this
*/
proto.api.grekcy.v1alpha2.Article.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.grekcy.v1alpha2.Article} returns this
 */
proto.api.grekcy.v1alpha2.Article.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.grekcy.v1alpha2.Article.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.Timestamp crawled_at = 10;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.api.grekcy.v1alpha2.Article.prototype.getCrawledAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 10));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.api.grekcy.v1alpha2.Article} returns this
*/
proto.api.grekcy.v1alpha2.Article.prototype.setCrawledAt = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.grekcy.v1alpha2.Article} returns this
 */
proto.api.grekcy.v1alpha2.Article.prototype.clearCrawledAt = function() {
  return this.setCrawledAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.grekcy.v1alpha2.Article.prototype.hasCrawledAt = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional bool readed = 11;
 * @return {boolean}
 */
proto.api.grekcy.v1alpha2.Article.prototype.getReaded = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.grekcy.v1alpha2.Article} returns this
 */
proto.api.grekcy.v1alpha2.Article.prototype.setReaded = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.grekcy.v1alpha2.ListArticleResp.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.grekcy.v1alpha2.ListArticleResp.prototype.toObject = function(opt_includeInstance) {
  return proto.api.grekcy.v1alpha2.ListArticleResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.grekcy.v1alpha2.ListArticleResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.grekcy.v1alpha2.ListArticleResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.api.grekcy.v1alpha2.Article.toObject, includeInstance),
    next: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.grekcy.v1alpha2.ListArticleResp}
 */
proto.api.grekcy.v1alpha2.ListArticleResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.grekcy.v1alpha2.ListArticleResp;
  return proto.api.grekcy.v1alpha2.ListArticleResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.grekcy.v1alpha2.ListArticleResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.grekcy.v1alpha2.ListArticleResp}
 */
proto.api.grekcy.v1alpha2.ListArticleResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.grekcy.v1alpha2.Article;
      reader.readMessage(value,proto.api.grekcy.v1alpha2.Article.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNext(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.grekcy.v1alpha2.ListArticleResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.grekcy.v1alpha2.ListArticleResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.grekcy.v1alpha2.ListArticleResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.grekcy.v1alpha2.ListArticleResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.api.grekcy.v1alpha2.Article.serializeBinaryToWriter
    );
  }
  f = message.getNext();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated Article items = 1;
 * @return {!Array<!proto.api.grekcy.v1alpha2.Article>}
 */
proto.api.grekcy.v1alpha2.ListArticleResp.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.api.grekcy.v1alpha2.Article>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.grekcy.v1alpha2.Article, 1));
};


/**
 * @param {!Array<!proto.api.grekcy.v1alpha2.Article>} value
 * @return {!proto.api.grekcy.v1alpha2.ListArticleResp} returns this
*/
proto.api.grekcy.v1alpha2.ListArticleResp.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.api.grekcy.v1alpha2.Article=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.grekcy.v1alpha2.Article}
 */
proto.api.grekcy.v1alpha2.ListArticleResp.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.api.grekcy.v1alpha2.Article, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.grekcy.v1alpha2.ListArticleResp} returns this
 */
proto.api.grekcy.v1alpha2.ListArticleResp.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional string next = 2;
 * @return {string}
 */
proto.api.grekcy.v1alpha2.ListArticleResp.prototype.getNext = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.grekcy.v1alpha2.ListArticleResp} returns this
 */
proto.api.grekcy.v1alpha2.ListArticleResp.prototype.setNext = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * @enum {number}
 */
proto.api.grekcy.v1alpha2.ListOrder = {
  NEWEST_FIRST: 0,
  OLDEST_FIRST: 1
};

goog.object.extend(exports, proto.api.grekcy.v1alpha2);
