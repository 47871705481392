/**
 * @fileoverview gRPC-Web generated client stub for api.grekcy.v1alpha2
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v4.23.3
// source: grekcy_v1alpha2.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as grekcy_v1alpha2_pb from './grekcy_v1alpha2_pb';
import * as google_protobuf_empty_pb from 'google-protobuf/google/protobuf/empty_pb';
import * as google_protobuf_wrappers_pb from 'google-protobuf/google/protobuf/wrappers_pb';


export class GrekcyClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorVersion = new grpcWeb.MethodDescriptor(
    '/api.grekcy.v1alpha2.Grekcy/Version',
    grpcWeb.MethodType.UNARY,
    google_protobuf_empty_pb.Empty,
    google_protobuf_wrappers_pb.StringValue,
    (request: google_protobuf_empty_pb.Empty) => {
      return request.serializeBinary();
    },
    google_protobuf_wrappers_pb.StringValue.deserializeBinary
  );

  version(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null): Promise<google_protobuf_wrappers_pb.StringValue>;

  version(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: google_protobuf_wrappers_pb.StringValue) => void): grpcWeb.ClientReadableStream<google_protobuf_wrappers_pb.StringValue>;

  version(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: google_protobuf_wrappers_pb.StringValue) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.grekcy.v1alpha2.Grekcy/Version',
        request,
        metadata || {},
        this.methodDescriptorVersion,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.grekcy.v1alpha2.Grekcy/Version',
    request,
    metadata || {},
    this.methodDescriptorVersion);
  }

  methodDescriptorLoginWithGoogle = new grpcWeb.MethodDescriptor(
    '/api.grekcy.v1alpha2.Grekcy/LoginWithGoogle',
    grpcWeb.MethodType.UNARY,
    grekcy_v1alpha2_pb.GoogleLoginReq,
    google_protobuf_wrappers_pb.StringValue,
    (request: grekcy_v1alpha2_pb.GoogleLoginReq) => {
      return request.serializeBinary();
    },
    google_protobuf_wrappers_pb.StringValue.deserializeBinary
  );

  loginWithGoogle(
    request: grekcy_v1alpha2_pb.GoogleLoginReq,
    metadata: grpcWeb.Metadata | null): Promise<google_protobuf_wrappers_pb.StringValue>;

  loginWithGoogle(
    request: grekcy_v1alpha2_pb.GoogleLoginReq,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: google_protobuf_wrappers_pb.StringValue) => void): grpcWeb.ClientReadableStream<google_protobuf_wrappers_pb.StringValue>;

  loginWithGoogle(
    request: grekcy_v1alpha2_pb.GoogleLoginReq,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: google_protobuf_wrappers_pb.StringValue) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.grekcy.v1alpha2.Grekcy/LoginWithGoogle',
        request,
        metadata || {},
        this.methodDescriptorLoginWithGoogle,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.grekcy.v1alpha2.Grekcy/LoginWithGoogle',
    request,
    metadata || {},
    this.methodDescriptorLoginWithGoogle);
  }

  methodDescriptorGetUserSettings = new grpcWeb.MethodDescriptor(
    '/api.grekcy.v1alpha2.Grekcy/GetUserSettings',
    grpcWeb.MethodType.UNARY,
    google_protobuf_empty_pb.Empty,
    grekcy_v1alpha2_pb.UserSettings,
    (request: google_protobuf_empty_pb.Empty) => {
      return request.serializeBinary();
    },
    grekcy_v1alpha2_pb.UserSettings.deserializeBinary
  );

  getUserSettings(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null): Promise<grekcy_v1alpha2_pb.UserSettings>;

  getUserSettings(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: grekcy_v1alpha2_pb.UserSettings) => void): grpcWeb.ClientReadableStream<grekcy_v1alpha2_pb.UserSettings>;

  getUserSettings(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: grekcy_v1alpha2_pb.UserSettings) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.grekcy.v1alpha2.Grekcy/GetUserSettings',
        request,
        metadata || {},
        this.methodDescriptorGetUserSettings,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.grekcy.v1alpha2.Grekcy/GetUserSettings',
    request,
    metadata || {},
    this.methodDescriptorGetUserSettings);
  }

  methodDescriptorUpdateUserSettings = new grpcWeb.MethodDescriptor(
    '/api.grekcy.v1alpha2.Grekcy/UpdateUserSettings',
    grpcWeb.MethodType.UNARY,
    grekcy_v1alpha2_pb.UpdateUserSettingsReq,
    google_protobuf_empty_pb.Empty,
    (request: grekcy_v1alpha2_pb.UpdateUserSettingsReq) => {
      return request.serializeBinary();
    },
    google_protobuf_empty_pb.Empty.deserializeBinary
  );

  updateUserSettings(
    request: grekcy_v1alpha2_pb.UpdateUserSettingsReq,
    metadata: grpcWeb.Metadata | null): Promise<google_protobuf_empty_pb.Empty>;

  updateUserSettings(
    request: grekcy_v1alpha2_pb.UpdateUserSettingsReq,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: google_protobuf_empty_pb.Empty) => void): grpcWeb.ClientReadableStream<google_protobuf_empty_pb.Empty>;

  updateUserSettings(
    request: grekcy_v1alpha2_pb.UpdateUserSettingsReq,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: google_protobuf_empty_pb.Empty) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.grekcy.v1alpha2.Grekcy/UpdateUserSettings',
        request,
        metadata || {},
        this.methodDescriptorUpdateUserSettings,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.grekcy.v1alpha2.Grekcy/UpdateUserSettings',
    request,
    metadata || {},
    this.methodDescriptorUpdateUserSettings);
  }

  methodDescriptorCreateSubscription = new grpcWeb.MethodDescriptor(
    '/api.grekcy.v1alpha2.Grekcy/CreateSubscription',
    grpcWeb.MethodType.UNARY,
    google_protobuf_wrappers_pb.StringValue,
    grekcy_v1alpha2_pb.Subscription,
    (request: google_protobuf_wrappers_pb.StringValue) => {
      return request.serializeBinary();
    },
    grekcy_v1alpha2_pb.Subscription.deserializeBinary
  );

  createSubscription(
    request: google_protobuf_wrappers_pb.StringValue,
    metadata: grpcWeb.Metadata | null): Promise<grekcy_v1alpha2_pb.Subscription>;

  createSubscription(
    request: google_protobuf_wrappers_pb.StringValue,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: grekcy_v1alpha2_pb.Subscription) => void): grpcWeb.ClientReadableStream<grekcy_v1alpha2_pb.Subscription>;

  createSubscription(
    request: google_protobuf_wrappers_pb.StringValue,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: grekcy_v1alpha2_pb.Subscription) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.grekcy.v1alpha2.Grekcy/CreateSubscription',
        request,
        metadata || {},
        this.methodDescriptorCreateSubscription,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.grekcy.v1alpha2.Grekcy/CreateSubscription',
    request,
    metadata || {},
    this.methodDescriptorCreateSubscription);
  }

  methodDescriptorListSubscriptions = new grpcWeb.MethodDescriptor(
    '/api.grekcy.v1alpha2.Grekcy/ListSubscriptions',
    grpcWeb.MethodType.UNARY,
    google_protobuf_empty_pb.Empty,
    grekcy_v1alpha2_pb.ListSubscriptionResp,
    (request: google_protobuf_empty_pb.Empty) => {
      return request.serializeBinary();
    },
    grekcy_v1alpha2_pb.ListSubscriptionResp.deserializeBinary
  );

  listSubscriptions(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null): Promise<grekcy_v1alpha2_pb.ListSubscriptionResp>;

  listSubscriptions(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: grekcy_v1alpha2_pb.ListSubscriptionResp) => void): grpcWeb.ClientReadableStream<grekcy_v1alpha2_pb.ListSubscriptionResp>;

  listSubscriptions(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: grekcy_v1alpha2_pb.ListSubscriptionResp) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.grekcy.v1alpha2.Grekcy/ListSubscriptions',
        request,
        metadata || {},
        this.methodDescriptorListSubscriptions,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.grekcy.v1alpha2.Grekcy/ListSubscriptions',
    request,
    metadata || {},
    this.methodDescriptorListSubscriptions);
  }

  methodDescriptorListSubscriptionConfig = new grpcWeb.MethodDescriptor(
    '/api.grekcy.v1alpha2.Grekcy/ListSubscriptionConfig',
    grpcWeb.MethodType.UNARY,
    google_protobuf_empty_pb.Empty,
    grekcy_v1alpha2_pb.ListSubscriptionConfigResp,
    (request: google_protobuf_empty_pb.Empty) => {
      return request.serializeBinary();
    },
    grekcy_v1alpha2_pb.ListSubscriptionConfigResp.deserializeBinary
  );

  listSubscriptionConfig(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null): Promise<grekcy_v1alpha2_pb.ListSubscriptionConfigResp>;

  listSubscriptionConfig(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: grekcy_v1alpha2_pb.ListSubscriptionConfigResp) => void): grpcWeb.ClientReadableStream<grekcy_v1alpha2_pb.ListSubscriptionConfigResp>;

  listSubscriptionConfig(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: grekcy_v1alpha2_pb.ListSubscriptionConfigResp) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.grekcy.v1alpha2.Grekcy/ListSubscriptionConfig',
        request,
        metadata || {},
        this.methodDescriptorListSubscriptionConfig,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.grekcy.v1alpha2.Grekcy/ListSubscriptionConfig',
    request,
    metadata || {},
    this.methodDescriptorListSubscriptionConfig);
  }

  methodDescriptorUpdateSubscriptionConfig = new grpcWeb.MethodDescriptor(
    '/api.grekcy.v1alpha2.Grekcy/UpdateSubscriptionConfig',
    grpcWeb.MethodType.UNARY,
    grekcy_v1alpha2_pb.UpdateSubscriptionConfigReq,
    google_protobuf_empty_pb.Empty,
    (request: grekcy_v1alpha2_pb.UpdateSubscriptionConfigReq) => {
      return request.serializeBinary();
    },
    google_protobuf_empty_pb.Empty.deserializeBinary
  );

  updateSubscriptionConfig(
    request: grekcy_v1alpha2_pb.UpdateSubscriptionConfigReq,
    metadata: grpcWeb.Metadata | null): Promise<google_protobuf_empty_pb.Empty>;

  updateSubscriptionConfig(
    request: grekcy_v1alpha2_pb.UpdateSubscriptionConfigReq,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: google_protobuf_empty_pb.Empty) => void): grpcWeb.ClientReadableStream<google_protobuf_empty_pb.Empty>;

  updateSubscriptionConfig(
    request: grekcy_v1alpha2_pb.UpdateSubscriptionConfigReq,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: google_protobuf_empty_pb.Empty) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.grekcy.v1alpha2.Grekcy/UpdateSubscriptionConfig',
        request,
        metadata || {},
        this.methodDescriptorUpdateSubscriptionConfig,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.grekcy.v1alpha2.Grekcy/UpdateSubscriptionConfig',
    request,
    metadata || {},
    this.methodDescriptorUpdateSubscriptionConfig);
  }

  methodDescriptorParseUrlForSubscription = new grpcWeb.MethodDescriptor(
    '/api.grekcy.v1alpha2.Grekcy/ParseUrlForSubscription',
    grpcWeb.MethodType.UNARY,
    google_protobuf_wrappers_pb.StringValue,
    grekcy_v1alpha2_pb.ParseUrlResp,
    (request: google_protobuf_wrappers_pb.StringValue) => {
      return request.serializeBinary();
    },
    grekcy_v1alpha2_pb.ParseUrlResp.deserializeBinary
  );

  parseUrlForSubscription(
    request: google_protobuf_wrappers_pb.StringValue,
    metadata: grpcWeb.Metadata | null): Promise<grekcy_v1alpha2_pb.ParseUrlResp>;

  parseUrlForSubscription(
    request: google_protobuf_wrappers_pb.StringValue,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: grekcy_v1alpha2_pb.ParseUrlResp) => void): grpcWeb.ClientReadableStream<grekcy_v1alpha2_pb.ParseUrlResp>;

  parseUrlForSubscription(
    request: google_protobuf_wrappers_pb.StringValue,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: grekcy_v1alpha2_pb.ParseUrlResp) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.grekcy.v1alpha2.Grekcy/ParseUrlForSubscription',
        request,
        metadata || {},
        this.methodDescriptorParseUrlForSubscription,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.grekcy.v1alpha2.Grekcy/ParseUrlForSubscription',
    request,
    metadata || {},
    this.methodDescriptorParseUrlForSubscription);
  }

  methodDescriptorListUnread = new grpcWeb.MethodDescriptor(
    '/api.grekcy.v1alpha2.Grekcy/ListUnread',
    grpcWeb.MethodType.UNARY,
    google_protobuf_wrappers_pb.StringValue,
    grekcy_v1alpha2_pb.ListUnreadResp,
    (request: google_protobuf_wrappers_pb.StringValue) => {
      return request.serializeBinary();
    },
    grekcy_v1alpha2_pb.ListUnreadResp.deserializeBinary
  );

  listUnread(
    request: google_protobuf_wrappers_pb.StringValue,
    metadata: grpcWeb.Metadata | null): Promise<grekcy_v1alpha2_pb.ListUnreadResp>;

  listUnread(
    request: google_protobuf_wrappers_pb.StringValue,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: grekcy_v1alpha2_pb.ListUnreadResp) => void): grpcWeb.ClientReadableStream<grekcy_v1alpha2_pb.ListUnreadResp>;

  listUnread(
    request: google_protobuf_wrappers_pb.StringValue,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: grekcy_v1alpha2_pb.ListUnreadResp) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.grekcy.v1alpha2.Grekcy/ListUnread',
        request,
        metadata || {},
        this.methodDescriptorListUnread,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.grekcy.v1alpha2.Grekcy/ListUnread',
    request,
    metadata || {},
    this.methodDescriptorListUnread);
  }

  methodDescriptorListArticle = new grpcWeb.MethodDescriptor(
    '/api.grekcy.v1alpha2.Grekcy/ListArticle',
    grpcWeb.MethodType.UNARY,
    grekcy_v1alpha2_pb.ListArticleReq,
    grekcy_v1alpha2_pb.ListArticleResp,
    (request: grekcy_v1alpha2_pb.ListArticleReq) => {
      return request.serializeBinary();
    },
    grekcy_v1alpha2_pb.ListArticleResp.deserializeBinary
  );

  listArticle(
    request: grekcy_v1alpha2_pb.ListArticleReq,
    metadata: grpcWeb.Metadata | null): Promise<grekcy_v1alpha2_pb.ListArticleResp>;

  listArticle(
    request: grekcy_v1alpha2_pb.ListArticleReq,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: grekcy_v1alpha2_pb.ListArticleResp) => void): grpcWeb.ClientReadableStream<grekcy_v1alpha2_pb.ListArticleResp>;

  listArticle(
    request: grekcy_v1alpha2_pb.ListArticleReq,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: grekcy_v1alpha2_pb.ListArticleResp) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.grekcy.v1alpha2.Grekcy/ListArticle',
        request,
        metadata || {},
        this.methodDescriptorListArticle,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.grekcy.v1alpha2.Grekcy/ListArticle',
    request,
    metadata || {},
    this.methodDescriptorListArticle);
  }

  methodDescriptorGetArticle = new grpcWeb.MethodDescriptor(
    '/api.grekcy.v1alpha2.Grekcy/GetArticle',
    grpcWeb.MethodType.UNARY,
    google_protobuf_wrappers_pb.StringValue,
    grekcy_v1alpha2_pb.Article,
    (request: google_protobuf_wrappers_pb.StringValue) => {
      return request.serializeBinary();
    },
    grekcy_v1alpha2_pb.Article.deserializeBinary
  );

  getArticle(
    request: google_protobuf_wrappers_pb.StringValue,
    metadata: grpcWeb.Metadata | null): Promise<grekcy_v1alpha2_pb.Article>;

  getArticle(
    request: google_protobuf_wrappers_pb.StringValue,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: grekcy_v1alpha2_pb.Article) => void): grpcWeb.ClientReadableStream<grekcy_v1alpha2_pb.Article>;

  getArticle(
    request: google_protobuf_wrappers_pb.StringValue,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: grekcy_v1alpha2_pb.Article) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.grekcy.v1alpha2.Grekcy/GetArticle',
        request,
        metadata || {},
        this.methodDescriptorGetArticle,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.grekcy.v1alpha2.Grekcy/GetArticle',
    request,
    metadata || {},
    this.methodDescriptorGetArticle);
  }

  methodDescriptorGetReadability = new grpcWeb.MethodDescriptor(
    '/api.grekcy.v1alpha2.Grekcy/GetReadability',
    grpcWeb.MethodType.UNARY,
    google_protobuf_wrappers_pb.StringValue,
    google_protobuf_wrappers_pb.StringValue,
    (request: google_protobuf_wrappers_pb.StringValue) => {
      return request.serializeBinary();
    },
    google_protobuf_wrappers_pb.StringValue.deserializeBinary
  );

  getReadability(
    request: google_protobuf_wrappers_pb.StringValue,
    metadata: grpcWeb.Metadata | null): Promise<google_protobuf_wrappers_pb.StringValue>;

  getReadability(
    request: google_protobuf_wrappers_pb.StringValue,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: google_protobuf_wrappers_pb.StringValue) => void): grpcWeb.ClientReadableStream<google_protobuf_wrappers_pb.StringValue>;

  getReadability(
    request: google_protobuf_wrappers_pb.StringValue,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: google_protobuf_wrappers_pb.StringValue) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.grekcy.v1alpha2.Grekcy/GetReadability',
        request,
        metadata || {},
        this.methodDescriptorGetReadability,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.grekcy.v1alpha2.Grekcy/GetReadability',
    request,
    metadata || {},
    this.methodDescriptorGetReadability);
  }

  methodDescriptorMarkAsRead = new grpcWeb.MethodDescriptor(
    '/api.grekcy.v1alpha2.Grekcy/MarkAsRead',
    grpcWeb.MethodType.UNARY,
    google_protobuf_wrappers_pb.StringValue,
    google_protobuf_empty_pb.Empty,
    (request: google_protobuf_wrappers_pb.StringValue) => {
      return request.serializeBinary();
    },
    google_protobuf_empty_pb.Empty.deserializeBinary
  );

  markAsRead(
    request: google_protobuf_wrappers_pb.StringValue,
    metadata: grpcWeb.Metadata | null): Promise<google_protobuf_empty_pb.Empty>;

  markAsRead(
    request: google_protobuf_wrappers_pb.StringValue,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: google_protobuf_empty_pb.Empty) => void): grpcWeb.ClientReadableStream<google_protobuf_empty_pb.Empty>;

  markAsRead(
    request: google_protobuf_wrappers_pb.StringValue,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: google_protobuf_empty_pb.Empty) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.grekcy.v1alpha2.Grekcy/MarkAsRead',
        request,
        metadata || {},
        this.methodDescriptorMarkAsRead,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.grekcy.v1alpha2.Grekcy/MarkAsRead',
    request,
    metadata || {},
    this.methodDescriptorMarkAsRead);
  }

  methodDescriptorSaveToPocket = new grpcWeb.MethodDescriptor(
    '/api.grekcy.v1alpha2.Grekcy/SaveToPocket',
    grpcWeb.MethodType.UNARY,
    google_protobuf_wrappers_pb.StringValue,
    google_protobuf_empty_pb.Empty,
    (request: google_protobuf_wrappers_pb.StringValue) => {
      return request.serializeBinary();
    },
    google_protobuf_empty_pb.Empty.deserializeBinary
  );

  saveToPocket(
    request: google_protobuf_wrappers_pb.StringValue,
    metadata: grpcWeb.Metadata | null): Promise<google_protobuf_empty_pb.Empty>;

  saveToPocket(
    request: google_protobuf_wrappers_pb.StringValue,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: google_protobuf_empty_pb.Empty) => void): grpcWeb.ClientReadableStream<google_protobuf_empty_pb.Empty>;

  saveToPocket(
    request: google_protobuf_wrappers_pb.StringValue,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: google_protobuf_empty_pb.Empty) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.grekcy.v1alpha2.Grekcy/SaveToPocket',
        request,
        metadata || {},
        this.methodDescriptorSaveToPocket,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.grekcy.v1alpha2.Grekcy/SaveToPocket',
    request,
    metadata || {},
    this.methodDescriptorSaveToPocket);
  }

}

