import { Alert, Container, Typography } from '@mui/material';
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../lib/AuthProvider';
import { useGrekcy } from '../lib/GrekcyProvider';

type LoginPageProp = {};

export default function LoginPage({}: LoginPageProp): JSX.Element {
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const [app, api] = useGrekcy();
  const auth = useAuth();

  return (
    <Container>
      <Typography variant="h3">Login</Typography>
      {error && <Alert severity="error">{error}</Alert>}
      <GoogleOAuthProvider clientId={process.env.REACT_APP_OAUTH_CLIENT_ID!}>
        <GoogleLogin
          onSuccess={(resp) => {
            api.loginWithGoogle(resp.credential!, resp.clientId).then((r) => {
              auth.setToken(r);
              navigate('/my');
            });
          }}
          onError={() => setError('Login Failed')}
        ></GoogleLogin>
      </GoogleOAuthProvider>
    </Container>
  );
}
