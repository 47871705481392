import AddIcon from '@mui/icons-material/Add';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Input,
  InputLabel,
  Radio,
  RadioGroup,
  Step,
  StepLabel,
  Stepper,
  Tooltip,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useGrekcyClient } from '../lib/GrekcyProvider';
import { Feed, ParseUrlResp } from '../lib/proto/grekcy_v1alpha2_pb';

enum AddStep {
  INPUT_URL,
  REVIEW_FEED,
  SELECT_FEED,
}

export default function AddSubscrition(): JSX.Element {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState('');
  const [url, setUrl] = useState('');
  const [activeStep, setActiveStep] = useState(0);
  const [parsedFeed, setParsedFeed] = useState<any>({});
  const [feedsFound, setFeedsFound] = useState<Feed.AsObject[]>([]);
  const [selectedFeed, setSelectedFeed] = useState('');
  const steps = ['Input RSS or page URL', 'Review Feed', 'Select Feed'];
  const api = useGrekcyClient();

  function handleOpen(): void {
    setOpen(true);
  }

  function handleClose(): void {
    setOpen(false);
  }

  const [skipped, setSkipped] = useState(new Set<number>());
  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const urlInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (open && activeStep === 0) {
      setTimeout(() => {
        urlInputRef.current && urlInputRef.current.focus();
      }, 0);
    }

    return () => {
      // reset dialog
      if (!open) {
        setError('');
        setUrl('');
        setActiveStep(0);
        setParsedFeed([]);
        setFeedsFound([]);
        setSelectedFeed('');
      }
    };
  }, [open, activeStep]);

  function validate(): boolean {
    setError('');
    switch (activeStep) {
      case AddStep.INPUT_URL:
        if (!url) {
          setError('please enter valid url');
          urlInputRef.current && urlInputRef.current.focus();
          return false;
        }

        try {
          new URL(url);
        } catch {
          setError('invalid url.');
          return false;
        }
        break;
      case AddStep.SELECT_FEED:
        if (!selectedFeed) {
          setError('please select feed to subscribe');
          return false;
        }
        return true;
    }
    return true;
  }

  async function handleNext(): Promise<void> {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    if (!validate()) return;

    function setNextStep(step: number): void {
      setActiveStep(step);
      setSkipped(newSkipped);
    }

    switch (activeStep) {
      case AddStep.INPUT_URL:
        try {
          console.log(`parsing: ${url}`);
          const resp = await api.parseUrlForSubscription(url);
          switch (resp.code) {
            case ParseUrlResp.Status.SUCCESS:
              setParsedFeed(resp.feed);

              setNextStep(AddStep.REVIEW_FEED);
              setSkipped(newSkipped);
              break;
            case ParseUrlResp.Status.ERROR:
              setError(resp.message);
              break;
            case ParseUrlResp.Status.FEED_NOT_FOUND:
              setError('feed not found');
              break;
            case ParseUrlResp.Status.MULTIPLE_FEED:
              setFeedsFound(resp.feedsList);
              setNextStep(AddStep.SELECT_FEED);
              break;
            default:
              console.log(resp);
              break;
          }
        } catch (e: any) {
          setError(e.message);
          return;
        }
        break;

      case AddStep.REVIEW_FEED:
        try {
          await api.createSubscription(url);
          handleClose();
        } catch (e: any) {
          setError(e.message);
        }
        break;

      case AddStep.SELECT_FEED:
        api.createSubscription(selectedFeed).catch((e) => setError(e.message));
        break;
    }
  }

  function handleBack(): void {
    setActiveStep(0);
  }

  return (
    <>
      <Tooltip title="subscribe..." placement="top">
        <AddIcon onClick={handleOpen} sx={{ cursor: 'pointer' }} />
      </Tooltip>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Subscribe feed or page</DialogTitle>
        <DialogContent>
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => {
              return (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>

          <Box sx={{ mt: 2, mb: 1 }}>
            {error && <Alert severity="error">{error}</Alert>}
            {(() => {
              switch (activeStep) {
                case AddStep.INPUT_URL:
                  return (
                    <>
                      <InputLabel htmlFor="url">Enter rss or page URL:</InputLabel>

                      <Input
                        inputRef={urlInputRef}
                        id="url"
                        sx={{ width: '100%' }}
                        name="url"
                        value={url}
                        onChange={(e) => setUrl(e.target.value.trim())}
                        placeholder="rss or page url"
                      />
                    </>
                  );
                case AddStep.REVIEW_FEED:
                  return (
                    <>
                      <h4>Review feed</h4>
                      <p>Title: {parsedFeed.title}</p>
                      <p>Feed URL: {parsedFeed.link}</p>
                    </>
                  );
                case AddStep.SELECT_FEED:
                  return (
                    <RadioGroup name="radio">
                      {feedsFound.map((e: any) => {
                        return (
                          <FormControlLabel
                            key={e.link}
                            label={`${e.title}: ${e.link}`}
                            value={e.link}
                            defaultValue={selectedFeed}
                            control={
                              <Radio
                                onChange={(e) => {
                                  setSelectedFeed(e.target.value);
                                }}
                              />
                            }
                          />
                        );
                      })}
                    </RadioGroup>
                  );
              }
            })()}
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Button disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
              Back
            </Button>

            <Box sx={{ flex: '1 1 auto' }} />
            <Button onClick={handleNext}>
              {activeStep === AddStep.REVIEW_FEED || activeStep === AddStep.SELECT_FEED ? 'Subscribe' : 'Next'}
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
