import { Container, Typography } from '@mui/material';

export default function Homepage(): JSX.Element {
  return (
    <Container>
      <h1>Home</h1>
      <Typography>Simple RSS reader for everyone.</Typography>

      <ul>
        <li>Handy navigation with keyboard k, j, r, o...</li>
      </ul>
    </Container>
  );
}
